import { useStaticQuery, graphql } from 'gatsby';

const useQueryCaseStudyTilesFeatured = () => {
  return useStaticQuery(
    graphql`
      query featured {
        allSanityCaseStudy(
          filter: { isFeatured: { eq: true } }
          sort: { fields: [year, client], order: DESC }
        ) {
          edges {
            node {
              ...caseStudyFeaturedTile
            }
          }
        }
      }
    `
  );
};

export default useQueryCaseStudyTilesFeatured;
