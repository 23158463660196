const useMailToQuerystring = (subject, content = null) => {
  const subjectEncoded = encodeURI(subject || '');
  const bodyEncoded = content && encodeURI(getStringifiedContent(content));
  return `?subject=${subjectEncoded}${bodyEncoded && `&body=${bodyEncoded}`}`;
};

const getStringifiedContent = (content) => {
  switch (typeof content) {
    case 'object':
      return Array.isArray(content) ? content.join('\n') : JSON.stringify(content);
    case 'string':
    default:
      return content;
  }
};

export default useMailToQuerystring;
