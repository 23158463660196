import { useStaticQuery, graphql } from 'gatsby';

const useQueryPeople = () => {
  return useStaticQuery(
    graphql`
      query {
        allPeople: allSanityPerson {
          edges {
            node {
              id
              name
              role
              questionPrompt {
                label
              }
              response
              headshot {
                asset {
                  fixed(width: 400) {
                    src
                  }
                }
              }
              flavourPic {
                asset {
                  fixed(width: 400) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  );
};

export default useQueryPeople;
