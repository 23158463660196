import React from 'react';
import useQueryAllRoyalsMedium from '../hooks/useQueryAllRoyalsMedium';
import useMediumSnippet from '../hooks/useMediumSnippet';

import { GRID } from '../constants';
import { Grid } from './containers';
import Post from './post';

const MediumPosts = ({ title, showTitle, count }) => {
  console.log('count' + count);
  const edges = useQueryAllRoyalsMedium()?.allRoyalsMedium.edges || [];
  edges.length = Math.min(edges.length, count);

  const [first, ...rest] = edges;

  const latestPost = getPostComponent(first.node, { className: 'featured' });
  const olderPosts = rest.map((edge) => getPostComponent(edge.node));

  const grid = olderPosts && olderPosts.length > 0 && (
    <Grid config={GRID.THREE_MAX} key={Math.random()}>
      {olderPosts}
    </Grid>
  );

  return (
    <section>
      {title && showTitle && <h1>{title}</h1>}
      {latestPost}
      {grid}
    </section>
  );
};

const getPostComponent = (node, props = {}) => {
  const { title, id, content, isoDate, friendlyDate } = node;
  const [snippet, image, url] = useMediumSnippet(content);

  return (
    <Post key={id} {...props}>
      <Post.FeatureImage src={image} />
      <Post.Title>{title}</Post.Title>
      <Post.PubDate iso={isoDate} friendly={friendlyDate} />
      <Post.Content>{snippet}</Post.Content>
      <Post.More to={url}>Read The Full Article</Post.More>
    </Post>
  );
};

export default MediumPosts;
