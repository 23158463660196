import React from 'react';
import { personTileStyles, headshotStyle, flavorPicStyle } from './personTile.module.scss';

const PersonTile = ({ data }) => {
  const { name, role, questionPrompt, response, headshot, flavourPic } = data;
  const headshotSrc = headshot.asset.fixed.src;
  const flavourPicSrc = flavourPic.asset.fixed.src;

  return (
    <article className={personTileStyles}>
      <section style={{ '--bg-image': `url(${headshotSrc})` }} className={headshotStyle}></section>
      <section style={{ '--bg-image': `url(${flavourPicSrc})` }} className={flavorPicStyle}>
        <h1>{name}</h1>
        <h2>{role}</h2>
        <dl>
          <dt>{questionPrompt.label}</dt>
          <dd>{response}</dd>
        </dl>
      </section>
    </article>
  );
};

export default PersonTile;
