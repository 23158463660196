import { useStaticQuery, graphql } from 'gatsby';

const useQueryAllRoyalsMedium = () => {
  return useStaticQuery(
    graphql`
      query {
        allRoyalsMedium: allFeedRoyalsMedium(sort: { fields: isoDate, order: DESC }) {
          edges {
            node {
              id
              title
              link
              content
              friendlyDate: isoDate(formatString: "MMM DD, YYYY")
              timeSincePosted: isoDate(fromNow: true)
              isoDate
            }
          }
        }
      }
    `
  );
};

export default useQueryAllRoyalsMedium;
