const BlockContent = require('@sanity/block-content-to-react');

import React from 'react';
import useQueryJobListings from '../../hooks/useQueryJobListings';
import JobListing from './jobListing';

const Jobs = ({ title }) => {
  const jobListings = getJobListings();

  return (
    <>
      {title && <h2 className='h2'>{title}</h2>}
      {jobListings && jobListings}
    </>
  );
};

const getJobListings = () => {
  const jobs = useQueryJobListings().allJobs.edges;
  if (jobs.length == 0) return null;

  return jobs.map(({ node }) => {
    const { headline, location, jobTitle, emailTemplate, _rawDescription, id } = node;

    return (
      <JobListing
        key={id}
        location={location}
        title={jobTitle}
        headline={headline}
        responseConfig={emailTemplate}
      >
        <BlockContent blocks={_rawDescription} />
      </JobListing>
    );
  });
};

export default Jobs;
export { JobListing };
