import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { logoStyles } from './clientLogos.module.scss';
import useQueryClientLogos from '../../hooks/useQueryClientLogos';

const ClientLogos = () => {
  const data = useQueryClientLogos();
  const logos = data.sanityClientLogos.logos.map((data) => {
    const { name, logo } = data;
    return <GatsbyImage image={logo.asset.gatsbyImageData} key={name} alt={name} />;
  });
  return <div className={logoStyles}>{logos}</div>;
};

export default ClientLogos;
