import parse from 'html-react-parser';

const useMediumPostContent = (rawContent) => {
  const children = parse(rawContent).props.children;

  // not 100% sure that medium snippets only ever have one image
  // so catch them all in an array and return the first, on the
  // assumption that the first image will be the feature image

  const data = {
    [CLASS_NAMES.SNIPPET]: null,
    [CLASS_NAMES.IMAGE]: [],
    [CLASS_NAMES.LINK]: null,
  };

  children.forEach((child) => {
    const { className } = child.props;
    switch (className) {
      case CLASS_NAMES.IMAGE:
        const imgTag = findTagInElement(child, 'img');
        const src = imgTag.props.src;
        data[CLASS_NAMES.IMAGE].push(src);
        break;
      case CLASS_NAMES.LINK:
        const anchorTag = findTagInElement(child, 'a');
        const url = anchorTag.props.href || null;
        data[CLASS_NAMES.LINK] = url;
        break;
      default:
        data[className] = child;
    }
  });

  return [data[CLASS_NAMES.SNIPPET], data[CLASS_NAMES.IMAGE][0], data[CLASS_NAMES.LINK]];
};

const CLASS_NAMES = {
  IMAGE: 'medium-feed-image',
  SNIPPET: 'medium-feed-snippet',
  LINK: 'medium-feed-link',
};

const findTagInElement = (element, tag) => {
  let found = element.type == tag ? element : false;
  const { children } = element.props;
  const childrenArray = Array.isArray(children) ? children : [children];

  if (!found) {
    childrenArray.forEach((child) => {
      found = findTagInElement(child, tag);
    });
  }
  return found;
};

export default useMediumPostContent;
