import { useStaticQuery, graphql } from 'gatsby';

const useQueryCaseStudyTiles = () => {
  return useStaticQuery(
    graphql`
      query all {
        allSanityCaseStudy(sort: { fields: [isFeatured, year], order: [DESC, DESC] }) {
          edges {
            node {
              ...caseStudyTile
            }
          }
        }
      }
    `
  );
};

export default useQueryCaseStudyTiles;
