import React, { useEffect, useState } from 'react';
import useQueryPeople from '../../hooks/useQueryPeople';

import { Grid } from '../containers';
import PersonTile from './personTile';

const People = ({ title }) => {
  const [tiles, setTiles] = useState();
  const { allPeople } = useQueryPeople();

  useEffect(() => {
    const shuffled = [...allPeople.edges].sort(() => Math.random() - 0.5);
    const els = shuffled.map((edge) => <PersonTile data={edge.node} key={edge.node.id} />);
    setTiles(els);
  }, []);

  return (
    <>
      {title && <h2 class='h2'>{title}</h2>}
      <Grid config={gridConfig}>{tiles}</Grid>
    </>
  );
};

const gridConfig = {
  colspans: { xs: 6 },
};

export default People;
export { PersonTile };
