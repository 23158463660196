import { useStaticQuery, graphql } from 'gatsby';

const useQueryCaseStudyTilesNonFeatured = () => {
  return useStaticQuery(
    graphql`
      query nonfeatured {
        allSanityCaseStudy(
          filter: { isFeatured: { ne: true } }
          sort: { fields: [year, client], order: DESC }
        ) {
          edges {
            node {
              ...caseStudyTile
            }
          }
        }
      }
    `
  );
};

export default useQueryCaseStudyTilesNonFeatured;
