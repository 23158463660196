import React from 'react';
// import Hero from '../components/hero';
// import HomeHero from '../components/homeHero';
import { FullbleedHero, TwoColumnHero, MaskedImageHero } from '../components/hero';

const usePageHeroFactory = (props) => {
  const { key, _type, title, subtitle, image, mask, images } = props;
  if (!props?.key) return null;

  let Container;
  let itemProps = {};

  switch (_type) {
    case HERO_TYPE.FULLBLEED:
      itemProps = { isFullbleed: true, title, subtitle, image };
      Container = FullbleedHero;
      break;
    case HERO_TYPE.TWOUP:
      itemProps = { isFullbleed: false, title, subtitle, image };
      Container = TwoColumnHero;
      break;
    case HERO_TYPE.MASK:
      itemProps = { title, subtitle, mask, images };
      Container = MaskedImageHero;
      break;
    default:
      throw new Error(`PageHeroFactory Error: Unrecognised page hero type [${_type}].`);
  }

  return [Container, key || _id, itemProps];
};

const HERO_TYPE = {
  FULLBLEED: 'pageHero.fullbleed',
  TWOUP: 'pageHero.2up',
  MASK: 'pageHero.mask',
};

export default usePageHeroFactory;
