import { useStaticQuery, graphql } from 'gatsby';

const useQueryClientLogos = () => {
  return useStaticQuery(
    graphql`
      {
        sanityClientLogos {
          logos {
            name
            logo {
              asset {
                gatsbyImageData(width: 150)
              }
            }
          }
        }
      }
    `
  );
};

export default useQueryClientLogos;
