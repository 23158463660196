import { useStaticQuery, graphql } from 'gatsby';

const useQueryJobListings = () => {
  return useStaticQuery(
    graphql`
      query {
        allJobs: allSanityJobListing(sort: { fields: order, order: DESC }) {
          edges {
            node {
              id
              jobTitle
              headline
              location
              emailTemplate {
                content
                subject
              }
              _rawDescription
            }
          }
        }
      }
    `
  );
};

export default useQueryJobListings;
