import React from 'react';
import Pullquote from '../components/pullquote';
import CTA from '../components/cta';

const serializers = {
  marks: {
    pullquote: (props) => <Pullquote>{props.children}</Pullquote>,
    cta: (props) => <CTA to={props.mark.to}>{props.children}</CTA>,
  },
};

export { serializers };
