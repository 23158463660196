const BlockContent = require('@sanity/block-content-to-react');

import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { serializers } from '../util/sanityPortableText';
import { VIMEO_BASE_URL } from '../constants';
import Pullquote from '../components/pullquote';
import CTA from '../components/cta';
import Jobs from '../components/jobs';
import RoyalsOffice from '../components/royalsOffice';
import ClientLogos from '../components/clientLogos';
import MediumPosts from '../components/mediumPosts';
import People from '../components/people';
import CaseStudyTiles from '../components/caseStudyTiles';
import ResponsiveVideo from '../components/responsiveVideo';
import HubspotFormWrapper from '../components/hubspotFormWrapper';

const temp = (props) => {
  return <pre>{JSON.stringify(props)}</pre>;
};

const usePageItemFactory = (props) => {
  const { key, _id, _type, showTitle, title, ...rest } = props;
  let Container;
  let itemProps = {};
  console.log(_type);
  switch (_type) {
    case ITEM_TYPE.RICHTEXT:
      itemProps = { blocks: rest.rawBlocks, serializers };
      Container = BlockContent;
      break;
    case ITEM_TYPE.IMAGE:
      itemProps = { alt: rest.alt || '', objectFit: 'contain', image: rest.asset.gatsbyImageData };
      Container = GatsbyImage;
      break;
    case ITEM_TYPE.VIMEO_EMBED:
      itemProps = { url: `${VIMEO_BASE_URL}/${rest.vimeoId}` };
      Container = ResponsiveVideo;
      break;
    case ITEM_TYPE.CTA:
      itemProps = { to: rest.to, title: rest.description || null, children: rest.label };
      Container = CTA;
      break;
    case ITEM_TYPE.PULLQUOTE:
      const children = <BlockContent blocks={rest.rawBlocks} />;
      itemProps = { children };
      Container = Pullquote;
      break;
    case ITEM_TYPE.JOBS:
      itemProps = { title: (showTitle && title) || null };
      Container = Jobs;
      break;
    case ITEM_TYPE.CLIENTLOGOS:
      Container = ClientLogos;
      break;
    case ITEM_TYPE.CASE_STUDY_TILES:
      itemProps = { headline: showTitle && title, filter: rest.filter };
      Container = CaseStudyTiles;
      break;
    case ITEM_TYPE.OFFICE:
      const { name, image, address, phones, mapLink } = rest;
      itemProps = { name, image, address, phones, mapLink };
      Container = RoyalsOffice;
      break;
    case ITEM_TYPE.MEDIUM_POSTS:
      const count = parseInt(rest.count) || null;
      itemProps = { title, showTitle, count };
      Container = MediumPosts;
      break;
    case ITEM_TYPE.HUBSPOT_FORM:
      const { subtitle, portalId, formId, successMessage } = rest;
      itemProps = { title, subtitle, portalId, formId, successMessage };
      Container = HubspotFormWrapper;
      break;
    case ITEM_TYPE.PEOPLE:
      itemProps = { title: (showTitle && title) || null };
      Container = People;
      break;
    default:
      throw new Error(`PageItemFactory Error: Unrecognised page item type [${_type}].`);
  }

  return [Container, key || _id, itemProps];
};

const ITEM_TYPE = {
  RICHTEXT: 'richText',
  IMAGE: 'contentImage',
  VIMEO_EMBED: 'vimeoEmbed',
  CTA: 'callToAction',
  PULLQUOTE: 'component.pullquote',
  JOBS: 'component.jobs',
  PEOPLE: 'component.people',
  CASE_STUDY_TILES: 'component.workTiles',
  CLIENTLOGOS: 'component.clientLogos',
  MEDIUM_POSTS: 'component.mediumPosts',
  HUBSPOT_FORM: 'hubspotForm',
  OFFICE: 'office',
};

export default usePageItemFactory;
