import React from 'react';
import useMailToQuerystring from '../../hooks/useMailToQuerystring';

// ToDo: move job application email address into CMS settings

const JobListing = ({ location, title, headline, responseConfig, ...props }) => {
  const emailAddress = 'membership@theroyals.com.au';
  const queryString = useMailToQuerystring(responseConfig.subject, responseConfig.content);
  const mailTo = `mailto:${emailAddress}${queryString}`;
  const titleAtt = `${title}${location && ` - ${location}`}`;
  return (
    <article
      data-content-type='jobListing'
      {...props}
      itemScope
      itemType='https://schema.org/JobPosting'
    >
      <details>
        <summary data-content-type='job-listing-summary' title={titleAtt}>
          <h1 itemProp='title'>{title}</h1>
          {location && (
            <small itemProp='jobLocation' itemScope itemˇype='https://schema.org/Place'>
              {location}
            </small>
          )}
          <h2>{headline}</h2>
          {getToggleButton()}
        </summary>
        {props.children}
        <section data-content-type='applicationInfo'>
          <p>
            Send your application or questions to <a href={`${mailTo}`}>{emailAddress}</a>.
          </p>
        </section>
      </details>
    </article>
  );
};

const getToggleButton = () => {
  // ToDo: this is a hack use  more semantic method
  return (
    <div className='toggle'>
      <span>Open</span>
      <span>Close</span>
    </div>
  );
};

export default JobListing;
