import { Section, Cols } from '../components/containers';

const usePageSectionFactory = (props) => {
  const {
    key, layoutStyle, alignItems, isCompact = false, items, backgroundColour, padding,
  } = props;
  const itemProps = { isCompact, backgroundColour, padding };
  let Container;
  switch (layoutStyle) {
    case LAYOUT_STYLE.BLOCK:
      itemProps[ALIGNMENT.BLOCK.PROP_NAME] = ALIGNMENT.BLOCK.VALUES[alignItems] || null;
      Container = Section;
      break;
    case LAYOUT_STYLE.COLUMN:
      itemProps[ALIGNMENT.COLUMN.PROP_NAME] = ALIGNMENT.COLUMN.VALUES[alignItems] || null;
      Container = Cols;
      break;
    default:
      console.warn(
        `Unknown pageSection layout: ${layoutStyle}. Using ${LAYOUT_STYLE.BLOCK} as fallback.`,
      );
      Container = LAYOUT_STYLE.BLOCK;
  }

  return [Container, key, itemProps, items];
};

const LAYOUT_STYLE = {
  BLOCK: 'block',
  COLUMN: 'column',
};

const ALIGNMENT = {
  BLOCK: {
    PROP_NAME: 'halign',
    VALUES: {
      START: 'left',
      CENTER: 'center',
      END: 'right',
    },
  },
  COLUMN: {
    PROP_NAME: 'valign',
    VALUES: {
      START: 'top',
      CENTER: 'center',
      END: 'bottom',
    },
  },
};

export default usePageSectionFactory;
