import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import usePageHeroFactory from '../hooks/usePageHeroFactory';
import usePageItemFactory from '../hooks/usePageItemFactory';
import usePageSectionFactory from '../hooks/usePageSectionFactory';

export default function Page({ data }) {
  const page = data.sanityPage;
  const { metadataTitle, metadataDescription, pageHero, sections } = page;

  const hero = getHeroComponent(pageHero[0]);

  const sectionComponents = sections.map((section) => {
    const [Container, key, props, items, backgroundColour, padding] = usePageSectionFactory(section);
    const itemComponents = items.map((item) => getItemComponent(item));
    return (
      <Container key={key} {...props}>
        {itemComponents}
      </Container>
    );
  });

  return (
    <Layout template='default'>
      <SEO title={metadataTitle} description={metadataDescription} />
      {hero}
      {sectionComponents}
    </Layout>
  );
}

const getItemComponent = (item) => {
  try {
    const [Item, key, props] = usePageItemFactory(item);
    return <Item key={key} {...props} />;
  } catch (e) {
    console.warn(e.message);
    return null;
  }
};

const getHeroComponent = (pageHero) => {
  if (!pageHero) return null;

  try {
    const [Hero, key, props] = usePageHeroFactory(pageHero);
    // const [Item, key, props] = usePageItemFactory(item);
    return <Hero key={key} {...props} />;
  } catch (e) {
    console.warn(e.message);
    return null;
  }
};

export const pageQuery = graphql`
  query($id: String!) {
    sanityPage(_id: { eq: $id }) {
      ...pageMetadata
      ...pageHero
      sections {
        layoutStyle
        ...pageSection
      }
    }
  }
`;
