import React from 'react';
import CTA from '../cta';

const Post = (props) => {
  return (
    <article itemScope itemType='http://schema.org/Article' data-content-type='post' {...props}>
      <>{props.children}</>
    </article>
  );
};

const Title = ({ children }) => <h1 itemProp='name'>{children}</h1>;

const Author = (authorName) => (
  <div itemScope itemType='https://schema.org/Person'>
    <span itemProp='name'>{authorName}</span>
  </div>
);

const FeatureImage = ({ src }) => (
  <div className='featureImage' style={{ '--bg-src': `url("${src}")` }}></div>
);

const PubDate = ({ iso, friendly }) => (
  <time itemProp='datePublished' dateTime={iso || friendly}>
    {friendly}
  </time>
);

const Content = ({ children }) => <section itemProp='articleBody'>{children}</section>;
const More = ({ to, children }) => (
  <section data-content-type='readMore'>
    <CTA to={to} title='Read the full article.'>
      {children}
    </CTA>
  </section>
);

Post.Title = Title;
Post.Author = Author;
Post.FeatureImage = FeatureImage;
Post.PubDate = PubDate;
Post.Content = Content;
Post.More = More;

export default Post;
