import { LINK_TYPE } from '../constants';

const isRelativeURL = (url) => Boolean(url.match(/^\/[^\/]/));
const isRoyalsDomain = (url) =>
  Boolean(url.match(/^(http[s]?:\/\/){0,1}(www\.){0,1}theroyals\.com(\.au){0,1}(\/|$)/));

const isMailTo = (url) => Boolean(url.match(/^mailto/));
const isExternal = (url) => !isRelativeURL(url) && !isRoyalsDomain(url);

const getLinkType = (url) => {
  switch (true) {
    case isMailTo(url):
      return LINK_TYPE.MAIL_TO;
    case isExternal(url):
      return LINK_TYPE.EXTERNAL;
    default:
      return LINK_TYPE.INTERNAL;
  }
};

export { getLinkType };
