import React from 'react';

import useQueryCaseStudyTiles from '../../hooks/useQueryCaseStudyTiles';
import useQueryCaseStudyTilesFeatured from '../../hooks/useQueryCaseStudyTilesFeatured';
import useQueryCaseStudyTilesNonFeatured from '../../hooks/useQueryCaseStudyTilesNonFeatured';
import { CASE_STUDY_FILTER, GRID } from '../../constants';
import { Grid } from '../containers';
import CaseStudyTile from './tile';

import {
  caseStudyTileStyles,
  featuredTileStyle,
  gridHeadlineStyle,
} from './caseStudyTiles.module.scss';
import { getCaseStudyPathFromSlug } from '../../util/paths';

const CaseStudyTiles = ({ headline, filter }) => {
  const { query, grid, className } = getFilterConfig(filter);
  const edges = query().allSanityCaseStudy.edges;

  const tiles = edges.map((edge) => {
    const { client, projectName, thumbnail, slug } = edge.node;
    const path = getCaseStudyPathFromSlug(slug.value);
    return (
      <CaseStudyTile
        className={className}
        client={client}
        name={projectName}
        image={thumbnail}
        href={path}
        key={slug.value}
      />
    );
  });

  return (
    <Grid config={grid} key={Math.random()}>
      {headline && <h1 className={gridHeadlineStyle}>{headline}</h1>}
      {tiles}
    </Grid>
  );
};

const getFilterConfig = (filter) => {
  const config = {
    query: useQueryCaseStudyTiles,
    grid: GRID.DEFAULT,
    // className: caseStudyTileStyles,
  };

  switch (filter) {
    case CASE_STUDY_FILTER.FEATURED:
      config.query = useQueryCaseStudyTilesFeatured;
      config.grid = GRID.FEATURED;
      // config.className = `${caseStudyTileStyles} ${featuredTileStyle}`;
      config.className = featuredTileStyle;
      break;
    case CASE_STUDY_FILTER.NON_FEATURED:
      config.query = useQueryCaseStudyTilesNonFeatured;
      break;
  }
  return config;
};

// ------------------------------------------------
// -------------------- export --------------------

export default CaseStudyTiles;
export { CaseStudyTile };
